<template>
  <r-e-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      show-footer
      @click-submit="handleSubmit"
      @click-cancel="handleCancel"
      @close="handleCancel"
  >

  <r-e-table class="bg-white" ref="apartmentTableRef" :dataRequest="getApartmentTableData" 
                   :columns="batchColumn"  >
            <template slot="empty">
                <el-empty/>
            </template>
  </r-e-table>
  <div v-if="action === 'publish'"><i class="el-icon-warning" style="color:#E6A23C;font-size:16px;margin-right:5px"></i>请确认是否发布以上房源</div>
  <div v-else><i class="el-icon-warning" style="color:#E6A23C;font-size:16px;margin-right:5px"></i>请确认是否取消发布以上房源</div>
  </r-e-dialog>
  
</template>

<script>
import {batchColumn} from "@/views/rental-management/publish-apartment/data";
import { getBatchPublishList,submitBatchPublish,cancelBatchPublish} from "@/api/publish";
import {getApartmentByUUID} from "@/api/publish";
import {MessageSuccess, MessageError} from "@custom/message";

export default {
  name: "layer-edit-publish",
  data() {
    return {
      batchColumn: batchColumn(this),
      dialogTitle: "",
      dialogVisible: false,
      loading: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
    };
  },
  components: {  },
  methods: {
    getApartmentTableData(uuids) {
      console.log('this.uuids',this.uuids);
      return getBatchPublishList({uuids:this.uuids})
      .then(res => {
            console.log('接口返回的数据:', res); // 打印接口返回的数据
            const {isRenovation,isRent} = res;
            this.isRent = isRent;
            this.isRenovation = isRenovation;
            return res; // 如果需要，可以返回数据
        })
  },
    async openDialog(data,action) {
      let that = this;
      if(action === "publish"){
        this.dialogTitle = "发布"
      }else{
        this.dialogTitle = "取消"
      }
      this.uuids = data;
      this.action = action;
      this.dialogVisible = true;
    },
  handleSubmit() {   
  let that = this;
  console.log("isRent", this.isRent);
  
  // 发布操作
  if (this.action === "publish") {
    const loadingOptions = that.loadingOptions;
    const loading = that.$loading({ ...loadingOptions });

    // 如果是租赁房源
    if (this.isRent === true) {
      this.$confirm('所选房源中含已租房源，是否继续发布?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 如果是装修房源
        if (this.isRenovation === true) {
          this.$confirm('所选房源中含装修房源，是否继续发布?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 执行发布操作
            submitBatchPublish({ uuids: this.uuids }).then(res => {
              console.log("add", res);
              MessageSuccess('发布成功');
              that.handleCancel("update");
              loading.close();
            }).catch(err => {
              loading.close();
            });
          }).catch(() => {
            loading.close(); // 取消装修房源时关闭 loading
          });
        } else {
          // 如果不是装修房源，直接执行发布操作
          submitBatchPublish({ uuids: this.uuids }).then(res => {
            console.log("add", res);
            MessageSuccess('发布成功');
            that.handleCancel("update");
            loading.close();
          }).catch(err => {
            loading.close();
          });
        }
      }).catch(() => {
        loading.close(); // 取消已租房源时关闭 loading
      });
    } else {
      // 如果不是租赁房源，直接执行发布操作
      submitBatchPublish({ uuids: this.uuids }).then(res => {
        console.log("add", res);
        MessageSuccess('发布成功');
        that.handleCancel("update");
        loading.close();
      }).catch(err => {
        loading.close();
      });
    }

  // 下架操作
  } else {
    const loadingOptions = that.loadingOptions;
    const loading = that.$loading({ ...loadingOptions });

    cancelBatchPublish({ uuids: this.uuids }).then(res => {
      MessageSuccess('下架成功');
      loading.close();
      that.handleCancel("update");
    }).catch(err => {
      loading.close();
    });
  }
},
    handleCancel(closeType = "cancel") {
      // this.$refs["formPublish"].resetFields();
      // 取消、关闭弹窗
      this.dialogVisible = false;
      this.$emit("handleSearch");
    },
  }
};
</script>

<style lang="scss" scoped>
.el-select{
  width: 100%;
}
.flex{
  display: flex;
  /deep/ .el-form-item{
    flex: 1;
    white-space: nowrap;
  }
}
</style>
<style>
.v-modal{
  z-index: 2000!important;
}
</style>
